.App {
	text-align: center;
}

.app-bg {
	background-color: black;

	/* background-image: url("../src/images/cropForBg.jpg");
	background-repeat: repeat;
	background-size: contain; */

	/* background-image: url("../src/images/redsmoke.jpg");
	background-repeat: no-repeat;
	background-size: cover; */
}

/*<!-------------------------------------------------------------------->*/
/*<!--------------------------------APP--------------------------------->*/
/*<!-------------------------------------------------------------------->*/

/*<!--------Header-------->*/

header {
	height: 100%;
}

header .banner {
	width: 100%;
	height: 100%;
	top: 500px;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/*<!--------Navbar-------->*/

.navbar {
	width: 100%;
	position: sticky;
	top: 0;
}

.sticky {
	position: sticky;
	top: 0;
	z-index: 9999;
}

.navbar a {
	line-height: 50px;
}

@media only screen and (max-width: 992px) {
	.navbar-brand {
		justify-content: center;
	}
}

@media only screen and (min-width: 992px) {
	nav a {
		background: transparent;
		padding: 0.8rem;
		border-radius: 50%;
		display: flex;
		justify-items: center;
	}
}

@media only screen and (max-width: 992px) {
	nav a {
		background: transparent;
		padding: 0.1rem;
		border-radius: 50%;
		display: flex;
		justify-content: center;
	}
}

@media only screen and (min-width: 992px) {
	.font-size {
		font-size: 1.1em;
	}
}

@media only screen and (max-width: 992px) {
	.font-size {
		font-size: 0.8em;
	}
}

/* nav a {
	background: transparent;
	padding: 0.8rem;
	border-radius: 50%;
	display: flex;
	font-size: 1.1rem;
	justify-items: center;
} */

nav a:hover {
	color: rgb(177, 43, 58);
}

.font-nav {
	font-family: "monaco", "Consolas", "Lucida Console", monospace;
	color: rgb(174, 236, 231);
	text-decoration: none;
	font-size: 3em;
	font-style: normal;
	font-variant: small-caps;
	font-weight: 1000;
}

/* .font-size {
	font-size: 1em;
} */

/*<!--------Footer-------->*/

ul {
	list-style-type: none;
	margin-left: -25px;
}

.decorations-on-link {
	text-decoration: none;
}

.decorations-on-link:hover {
	color: rgb(174, 236, 231);
}

.item {
	margin-bottom: 10px;
}

.socialMedia {
	display: flex;
	justify-content: center;
	position: relative;
	bottom: -90px;
	align-items: flex-end;
	padding: 10px;
}

.contact-title {
	position: relative;
	top: -10px;
}
.contact {
	position: relative;
	bottom: -40px;
}

.email-logo {
	position: relative;
	bottom: -90px;
	right: -200px;
	color: white;
}

.content {
	height: 3000px;
}

/*<!--------Font Options-------->*/

@media only screen and (min-width: 992px) {
	.font-body {
		font-size: 1.3em;
	}
}

@media only screen and (max-width: 992px) {
	.font-body {
		font-size: 1em;
	}
}

@media only screen and (min-width: 992px) {
	.font-title {
		font-size: 3em;
	}
}

@media only screen and (max-width: 992px) {
	.font-title {
		font-size: 2em;
	}
}

.font-title {
	font-family: "monaco", "Consolas", "Lucida Console", monospace;
	/* font-size: 3em; */
	font-style: normal;
	font-variant: small-caps;
	font-weight: 1000;
}

.font-body {
	font-family: monospace;
	font-style: normal;
	font-variant: normal;
	font-weight: 700;
}

.colorWhite {
	color: whitesmoke;
}

.colorRed {
	color: rgb(177, 43, 58);
	text-decoration: none;
	/* HEX: #9e2130 */
}

.colorRed:hover {
	color: rgb(171, 217, 213);
	text-decoration: none;
	/* HEX: #9e2130 */
}

.colorOrange {
	color: rgba(209, 114, 59);
	/* HEX: #d1723b */
}

.colorPeach {
	color: rgb(227, 170, 152);
	/* HEX: #e3aa98 */
}

.colorLightTurq {
	color: rgb(171, 217, 213);
	/* HEX: #abd9d5 */
	/* Use on links */
}

.colorWhite {
	color: rgb(255, 255, 255);
	/* HEX: #abd9d5 */
	/* Use on links */
}

.font-weight-big {
	font-weight: 900;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

/*<!-------------------------------------------------------------------->*/
/*<!------------------------------HOMEPAGE------------------------------>*/
/*<!-------------------------------------------------------------------->*/

@media only screen and (min-width: 992px) {
	.officialVideo {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

@media only screen and (max-width: 992px) {
	.officialVideo {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

iframe .officialVideo {
	height: auto;
	width: 100%;
}

.sectionBlack {
	background-color: rgb(0, 0, 0);
	/* border: 5px solid;
	border-radius: 2em;
	border-color: rgb(0, 0, 0); */
	/* HEX: #9e2130 */
}

.sectionTurq {
	background-color: rgba(171, 217, 213, 0.363);
	border-radius: 1em;
	/* border: 5px solid;
	border-color: rgb(171, 217, 213); */
}

.sectionOrange {
	background-color: rgba(209, 114, 59);
	border: 5px solid;
	border-radius: 1em;
	border-color: rgba(209, 114, 59, 0.027);
	/* HEX: #d1723b */
}

.sectionPeach {
	background-color: rgba(204, 63, 20, 0.123);
	border-radius: 1em;
	/* border: 5px solid;
	border-color: rgb(227, 170, 152); */
	/* HEX: #e3aa98 */
}

.bdStyle {
	border: 5px solid;
	border-radius: 2em;
	border-color: rgb(255, 255, 255);
	z-index: 15;
}

.woodTif {
	background-image: url("../src/images/Tiffany_green_woodgrain_v2.png");
	background-repeat: repeat;
	background-size: contain;
	border-radius: 1em;
}

.woodPeach {
	background-image: url("../src/images/Peach_Woodgrain.png");
	background-repeat: repeat;
	background-size: contain;
	border-radius: 1em;
}

.woodOrange {
	background-image: url("../src/images/Orange_Woodgrain.png");
	background-repeat: repeat;
	background-size: contain;
	border-radius: 1em;
}

@media only screen and (min-width: 992px) {
	.bandName {
		width: 75%;
	}
}

@media only screen and (max-width: 992px) {
	.bandName {
		width: 100%;
	}
}

/*<!-------------------------------------------------------------------->*/
/*<!--------------------------------BIO--------------------------------->*/
/*<!-------------------------------------------------------------------->*/

@media only screen and (max-width: 768px) {
	.bio-pic-width {
		max-width: 250px;
	}
}

.baseBio-pic-width {
	/* width: 66%; */
	padding: auto;
}

.bio-pic-width {
	padding: auto;
	height: 100%;
}

.bioSticky {
	align-self: center;
	top: 1em;
	position: sticky;
	top: 0;
}

/*<!-------------------------------------------------------------------->*/
/*<!-------------------------------EVENTS------------------------------->*/
/*<!-------------------------------------------------------------------->*/

/*<!---------iframe--------->*/
.frameContain {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 110.5%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) 56.25% */
}

.responsive-iframe {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}

/*<!---------Tour Flyer--------->*/

/* @media screen and (max-width: 1200px) {
	.pic-width {
		margin: 0 10rem;
	}
}

.pic-width {
	max-width: 100%;
} */

/*<!--------Event Ticket-------->*/

@media only screen and (min-width: 992px) {
	.tourFlyer {
		width: 75%;
	}
}

@media only screen and (max-width: 992px) {
	.tourFlyer {
		width: 100%;
	}
}

figcaption {
	position: absolute;
	top: 2rem;
	margin: 0 20rem;
	font-size: 1.5em;
	color: black;
}

@media screen and (max-width: 1200px) {
	figcaption {
		margin: 0 10rem;
	}
}

.bgTable {
	border: 5px solid black;
	border-radius: 2em;
	height: auto;
	width: 100%;
	background: url(https://live.staticflickr.com/65535/52501259689_da67c1ced3_k.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}

.opcy {
	opacity: 100%;
}

.blackFade {
	background-color: #0000005e;
}

/*<!-------------------------------------------------------------------->*/
/*<!-------------------------------MEDIA-------------------------------->*/
/*<!-------------------------------------------------------------------->*/

/*<!--------Media Search-------->*/

#searchBar {
	background: none;
	border-bottom: 1px solid white;
	border-top: none;
	border-left: none;
	border-right: none;
	height: 30px;
}

#searchBar:focus {
	outline: none;
	border-bottom: 3px solid white;
	color: white;
}

::-webkit-input-placeholder {
	color: white;
	font-weight: bold;
}

.searchBtn {
	width: 30px;
	height: 30px;
	background: none;
	border: 1px solid white;
	color: white;
}

.searchBtn:focus {
	outline: none;
}

/*<!--------Media Video-------->*/

.video-container {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%;
}

.video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

/* .vintage__container {
	display: inline-block;
	margin: 25px auto 40px;
}

.vintage {
	font-family: "Six Caps", sans-serif;
	font-size: 50px;
	letter-spacing: 2px;
	line-height: 1;
	margin: 0;
	position: relative;
}

.vintage__top {
	background: linear-gradient(rgb(240, 220, 200), rgb(230, 200, 160));
	-webkit-background-clip: text;
	background-clip: text;
	position: absolute;
	z-index: 1;
	-webkit-text-fill-color: transparent;
}

.vintage__bot {
	text-shadow: 1px 0px rgb(85, 10, 0), 4px 2px rgb(90, 15, 5),
		3px 1px rgb(100, 20, 15), 8px 5px rgb(105, 25, 20), 6px 4px rgb(110, 30, 25),
		5px 2px rgb(115, 30, 30), 10px 6px rgb(120, 30, 35), 16px 9px black,
		7px 3px black, 20px 11px black, 14px 8px black, 24px 13px black,
		9px 4px rgba(0, 0, 0, 0.9), 18px 10px rgba(0, 0, 0, 0.7),
		11px 5px rgba(0, 0, 0, 0.5), 22px 14px rgba(0, 0, 0, 0.3),
		13px 6px rgba(0, 0, 0, 0.1), 26px 16px rgba(0, 0, 0, 0.1);
} */

/* .tilt {
	transform: matrix(1, -0.2, 0, 1, 0, 0);
	-ms-transform: matrix(1, -0.2, 0, 1, 0, 0);
	-webkit-transform: matrix(1, -0.2, 0, 1, 0, 0);
} */

.font-tilt {
	color: #f5f5f5;
	/* color: #9e2130; */
	font-family: "monaco", "Consolas", "Lucida Console", monospace;
	font-size: 2em;
	font-style: normal;
	font-variant: small-caps;
	font-weight: 1000;
	text-shadow: 2px 3px #c6a39a, 4px 5px #d1723b;
	/* margin-top: 0.3em; */
}

.font-Blue {
	color: #abd9d5;
	/* color: #9e2130; */
	font-family: "monaco", "Consolas", "Lucida Console", monospace;
	font-size: 2em;
	font-style: normal;
	font-variant: small-caps;
	font-weight: 1000;
	text-shadow: 2px 3px #c6a39a, 4px 5px #d1723b;
	/* margin-top: 0.3em; */
}

h1 span {
	display: inline-block;
	vertical-align: middle;
}

span.star {
	font-size: 20px;
}
